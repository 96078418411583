/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormAgent from '@/components/Form/AppFormAgent.vue'
import AppFormPopupAgent from '@/components/Form/popup/AppFormPopupAgent.vue'
import AppFormService from '@/components/Form/AppFormService.vue'
import AppFormPrice from '@/components/Form/AppFormPrice.vue'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Tab from '@/assets/js/Tab/Tab'
import Faq from '@/assets/js/Faq/Faq'
import Info from '@/assets/js/Info/Info'
import MenuSub from '@/assets/js/Menu/Sub/MenuSub'
import SearchFocus from '@/assets/js/Search/Focus/SearchFocus'
import MenuLeft from '@/assets/js/Menu/Left/MenuLeft'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import MenuMobileSub from '@/assets/js/Menu/Mobile/Sub/MenuMobileSub'
import HeaderService from '@/assets/js/Header/Service/HeaderService'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import ProductTab from '@/assets/js/Product/Tab/ProductTab'
import ProductContent from '@/assets/js/Product/Content/ProductContent'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonSearch from '@/assets/js/Button/Search/ButtonSearch'
import ButtonCount from '@/assets/js/Button/Count/ButtonCount'
import Short from '@/assets/js/Short/Short'
import Price from '@/assets/js/Price/Price'
import FilterButton from '@/assets/js/Filter/Button/FilterButton'
import FilterToggle from '@/assets/js/Filter/Toggle/FilterToggle'
import InputPayment from '@/assets/js/Input/InputPayment/InputPayment'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    Fancybox.bind('[data-fancybox-form]', {
        mainClass: 'fancybox---form'
    })

    new SwiperSlider()
    new Tab()
    new Faq()
    new Info()
    new MenuSub()
    new SearchFocus()
    new MenuLeft()
    new MenuMobile()
    new HeaderService()
    new HeaderMobile()
    new MenuMobileSub()
    new ProductTab()
    new ProductContent()
    new ButtonTop()
    new ButtonSearch()
    new ButtonCount()
    new Short()
    new Price()
    new FilterButton()
    new FilterToggle()
    new InputPayment()

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-agent') != null) {
        createApp({})
            .component('block-form-agent', AppFormAgent)
            .use(Maska)
            .use(form)
            .mount('#block--form-agent')
    }

    if(document.getElementById('block--form-popup-agent') != null) {
        createApp({})
            .component('block-form-popup-agent', AppFormPopupAgent)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup-agent')
    }

    if(document.getElementById('block--form-service') != null) {
        createApp({})
            .component('block-form-service', AppFormService)
            .use(Maska)
            .use(form)
            .mount('#block--form-service')
    }

    if(document.getElementById('block--form-price') != null) {
        createApp({})
            .component('block-form-price', AppFormPrice)
            .use(Maska)
            .use(form)
            .mount('#block--form-price')
    }
})
