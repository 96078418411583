/*global $*/
/*eslint no-undef: "error"*/
export default class MenuMobileSub {
    classItem = 'menu-mobile-sub--item'
    classButton = 'menu-mobile-sub--button'

    classItemOpen = 'menu-mobile-sub__item---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
