<template lang="pug">
.form.form---service(:class="{'form---success': sendForm}")
  .form__block
    .form__fields
      .form__field
        .input-text.input-text---white-background.input-text---input-weight-400
          input.input-text__input(
            type="text"
            :placeholder="formFieldNamePlaceholder"
            v-model="fieldName"
          )

      .form__field
        label.input-phone.input-phone---white(:class="{'input-phone---error': fieldError}")
          svg.input-phone__icon
            use(xlink:href="#icon-flag-ru")
          span.input-phone__prefix +7
          input.input-phone__input(
            type="tel"
            :placeholder="formFieldPhonePlaceholder"
            v-model="fieldPhone"
            v-maska="['(###) ###-##-##', '(###) ###-##-##']"
          )

      .form__field(v-if="smsView")
        .input-text.input-text---sms
          input.input-text__input(
            type="text"
            :placeholder="formFieldSmsPlaceholder"
            v-model="fieldSms"
          )

        .form__error(v-if="smsError" v-html="smsErrorText")

    .form__bottom
      .form__button
        .button.button---black.button---width(@click="getSmsSend(fieldSms)")
          .button__text(v-if="!smsView" v-html="formButtonName")
          .button__text(v-else v-html="formButtonNameSms")

      .form__consent(v-html="formConsent")

  .form__success
    p(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AppFormService',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldSmsPlaceholder",
    "formFieldPage",

    "formButtonName",
    "formButtonNameSms",

    "formConsent",

    "formSuccessText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldSms: '',
      files: '',
      sendForm: false,
      fieldError: false,
      smsView: false
    }
  },

  computed: {
    ...mapGetters([
        'smsStatus',
        'smsError',
        'smsErrorText',
        'smsCode'
    ])
  },

  watch: {
    smsStatus (newValue) {
      if (newValue === true) {
        this.getSend()
      }
    },

    fieldPhone (newValue) {
      if (newValue.includes('(89')) {
        this.fieldPhone = this.fieldPhone.replace('(89', '(9')
      }
    }
  },

  methods: {
    ...mapActions([
      'Send',
      'SmsSend'
    ]),

    getSmsSend (code) {
      if (!this.v$.$invalid) {
        this.smsView = true
        this.fieldError = false
        this.SmsSend({code, phone: this.fieldPhone})
      } else {
        this.smsView = false
        this.fieldError = true
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: '+7 ' + this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldSms =  ''
        this.smsView = false

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 5000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(15),
        maxLength: maxLength(15)
      }
    }
  }
}
</script>
