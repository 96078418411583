/*global $*/
/*eslint no-undef: "error"*/

export default class SearchFocus {
    classItem = 'search--focus'
    classBodyOverflow = 'overflow'

    constructor () {
        let self = this

        $(`.${self.classItem}`).focusin(function () {
            $(`body`).addClass(self.classBodyOverflow)
        })

        $(`.${self.classItem}`).focusout(function () {
            $(`body`).removeClass(self.classBodyOverflow)
        })
    }
}
