/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonCount {
    classItem = 'button-count--item'
    classButton = 'button-count--button'
    classInput = 'button-count--input'
    classCount = 'button-count--count'
    classChange = 'button-count--change'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let curCount = parseInt($(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).val())
            let curType = $(this).data('type')

            if (curType === 'plus') {
                curCount += 1
            }

            if (curType === 'minus') {
                if (curCount > 1) {
                    curCount -= 1
                }
            }

            $(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).text(curCount)
            $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).val(curCount)

            if ($(this).closest(`.${self.classItem}`).find(`.${self.classChange}`).length) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classChange}`).click()
            }
        })
    }
}
