/*global $*/
/*eslint no-undef: "error"*/
export default class MenuLeft {
    classItem = 'menu-left--item'
    classButton = 'menu-left--button'

    classItemOpen = 'menu-left__item---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
