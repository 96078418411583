/*global $*/
/*eslint no-undef: "error"*/
export default class Info {
    classKey = 'cookie---info'
    classButton = 'info--button'
    classItem = 'info--item'
    classItemOpen = 'info---open'

    constructor () {
        let self = this

        if (localStorage.getItem(self.classKey) === null) {
            $(`.${self.classItem}`).addClass(self.classItemOpen)
        }

        $(`.${self.classButton}`).click(function () {
            localStorage.setItem(self.classKey, 'da')
            $(this).closest(`.${self.classItem}`).removeClass(self.classItemOpen)
        })
    }
}
