/*global $*/
/*eslint no-undef: "error"*/
export default class ProductContent {
    classItem = 'product-content--item'
    classButton = 'product-content--button'
    classItemOpen = 'product-content---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textDo = $(this).data('do')
            let textTo = $(this).data('to')

            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)

            if ($(this).closest(`.${self.classItem}`).hasClass(self.classItemOpen)) {
                $(this).text(textDo)
            } else {
                $(this).text(textTo)
            }
        })
    }
}
