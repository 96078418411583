/*global $*/
/*eslint no-undef: "error"*/
export default class HeaderService {
    classItem = 'header-service--item'
    classButton = 'header-service--button'

    classItemOpen = 'header-service---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
