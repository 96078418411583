/*global $*/
/*eslint no-undef: "error"*/
export default class FilterToggle {
    classItem = 'filter-toggle--item'
    classButton = 'filter-toggle--button'

    classItemOpen = 'filter---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
