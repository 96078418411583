/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonSearch {
    classItem = 'button-search--item'
    classButton = 'button-search--button'
    classInfo = 'info---open'
    classHeader = 'page---header'
    classButtonOpen = 'open'
    classBodyOverflow = 'overflow'

    constructor () {
        let self = this

        $(`.${self.classButton}`).on('click', function () {
            $(`.${self.classItem}`).toggleClass(self.classButtonOpen)

            if ($(`.${self.classItem}`).hasClass(self.classButtonOpen)) {
                let heightInfo = 0
                let heightHeader = $(`.${self.classHeader}`).height()

                if ($(`.${self.classInfo}`).length) {
                    heightInfo = $(`.${self.classInfo}`).height()
                }

                $(`.${self.classItem}`).css('top', heightHeader + heightInfo)

                $(`.${self.classItem}`).find(`input`).focus()
                $(`body`).addClass(self.classBodyOverflow)
            } else {
                $(`body`).removeClass(self.classBodyOverflow)
            }
        })
    }
}
