/*global $*/
/*eslint no-undef: "error"*/
export default class MenuSub {
    classItem = 'menu--sub'
    classBodyOverflow = 'overflow'

    constructor () {
        let self = this

        $(`.${self.classItem}`).mouseenter(function () {
            $(`body`).addClass(self.classBodyOverflow)
        })

        $(`.${self.classItem}`).mouseleave(function () {
            $(`body`).removeClass(self.classBodyOverflow)
        })
    }
}
